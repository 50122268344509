/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {
  FaFacebookSquare,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaVimeoSquare,
  FaAlignLeft,
} from "react-icons/fa"
// import { useHover } from "react-use"

const Grid = props => (
  <div
    sx={{
      width: "100%",
      px: "0px",
      display: "grid",
      gridTemplateColumns: "repeat(9, 1fr)",
      gridAutoFlow: "dense",
      gridGap: "0px",
      "> div:nth-child(1)": {
        gridColumn: "1/7",
        gridRow: "1/4",
      },
      "> div:nth-child(2)": {
        gridColumn: "7/10",
        gridRow: "1/3",
      },
      "> div:nth-child(3)": {
        gridColumn: "7/10",
        gridRow: "3/4",
      },
    }}
    {...props}
  />
)

// 'repeat(auto-fill, minmax(220px, 1fr))'

const Container = props => (
  <div
    {...props}
    sx={{
      position: "relative",
      ">a>div": {
        transition: "filter 0.1s ease-in-out",
        filter: "brightness(100%)",
      },
      ">a>div:hover": {
        filter: "brightness(50%)",
      },
      ":hover": {
        ".ActuText": {
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    }}
  />
)

const Logo = props => (
  <div
    {...props}
    sx={{
      position: "absolute",
      top: "7px",
      left: "7px",
      opacity: 1,
      display: ["none", "block"],
    }}
  />
)

const ActuText = props => (
  <div
    {...props}
    sx={{
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      color: "rgba(255, 255, 255, 0.8)",
      padding: "25px 25px 20px 25px",
      fontSize: 1,
      display: "none",
      // height: "100px",
    }}
  />
)

const Items = ({ actualite }) => {
  const link =
    actualite &&
    actualite.Creations &&
    actualite.Creations[0] &&
    actualite.Creations[0].slug &&
    (actualite.Creations[0].isArtNum
      ? `/artnum/${actualite.Creations[0].slug}`
      : `/film/${actualite.Creations[0].slug}`)
  return (
    <Container>
      {/* <a href={actualite.Lien_URL} target="_blank" rel="noopener noreferrer"> */}
      <Link to={link}>
        <Img
          fluid={actualite.Image_principale_optimized.childImageSharp.fluid}
          alt={actualite.Titre_FR}
          placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
        />
        <Logo>
          {actualite.Source === "Facebook" && (
            <FaFacebookSquare color={"#FFF"} size={"25px"} />
          )}
          {actualite.Source === "Instagram" && (
            <FaInstagram color={"#FFF"} size={"25px"} />
          )}
          {actualite.Source === "Twitter" && (
            <FaTwitter color={"#FFF"} size={"25px"} />
          )}
          {actualite.Source === "YouTube" && (
            <FaYoutube color={"#FFF"} size={"25px"} />
          )}
          {actualite.Source === "Vimeo" && (
            <FaVimeoSquare color={"#FFF"} size={"25px"} />
          )}
          {actualite.Source === "Article" && (
            <FaAlignLeft color={"#FFF"} size={"25px"} />
          )}
        </Logo>
      </Link>
      {/* </a> */}
      <ActuText className={"ActuText"}>
        <div
          sx={{
            fontWeight: 300,
            margin: 0,
            padding: 0,
            lineHeight: "1em",
            color: "#FFF",
            fontSize: "2em",
          }}
        >
          {actualite.Titre_FR}
        </div>
      </ActuText>
    </Container>
  )
}

const Items2 = ({ slide }) => {
  const { Image_optimized, Titre_FR, Description_FR, link = "" } = slide
  return (
    <Container>
      <Link to={link}>
        <Img
          fluid={Image_optimized.childImageSharp.fluid}
          alt={Titre_FR}
          placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
        />
      </Link>
      {/* </a> */}
      <ActuText className={"ActuText"}>
        <div
          sx={{
            fontWeight: 300,
            margin: 0,
            padding: 0,
            lineHeight: "1em",
            color: "#FFF",
            fontSize: "2em",
          }}
        >
          {Titre_FR}
        </div>
      </ActuText>
    </Container>
  )
}

const HomeMosaic = ({ actualites, isGrey, slideshow }) => {
  return (
    <div>
      <Grid sx={{ backgroundColor: isGrey ? "lightgray" : "inherit" }}>
        {slideshow.slice(0, 3).map(slide => (
          <Items2 isFluid={true} key={slide.id} slide={slide} />
        ))}
      </Grid>
    </div>
  )
}

export default HomeMosaic
